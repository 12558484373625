/* general statuses */

.status-active {
    color: #37c936;
}
.status-inactive {
    color:red;
}
.status-pending {
    color :#ffcc00;
}
.status-blacklist {
    color:black;
}

// form label font size
.custom-label-size {
    font-size: 14px;
}

// .input-readonly{
//     &.form-control:disabled {
//         background-color: #fff;
//     }
// }

.form-control {
    &.sales-dropdown {
        width: 50%;
    }
}

.react-tel-input .form-control {
    width: 100% !important;
}

.react-tel-input :disabled {
    background-color: #efefef !important;
}

// submit button size
.btn-form-submit {
    width: 200px;
    text-align: center;
    margin-left: 1rem !important;
}

.btn-system-setting {
    width: fit-content;
    text-align: center;
}

.image-style {
    object-fit: contain;
    cursor: pointer
}

.image-div-style {
    display: flex;
    width: 250px;
    height: 250px;
    
    &.product {
        justify-content: center;
        width: auto;    
    }
    @media (max-width: 320px) {
        display: flex;
        width: 200px;
        height: 230px;
    }
}

.product-edit-button {
    // margin-left: -20px;
    // margin-top: -20px;
    background-color: #7367f0;
    border-radius: 50%;
    width: 1.5rem!important;
    height: 1.5rem!important;
    padding: 2px;
    cursor: pointer;
  }

.product-image-badge {
    height: 20px;
    margin-top: -10px;
    display: inline;
    position: absolute;
}

.product-add-image-btn {
    margin: 15px 30px;
}

.subpage {
    cursor: pointer;
}

.subpage:hover{
    background: #E4E1FE;
    transition: 0.1s;
}

.subpage-text-active {
    font-weight: 700;
    color: black!important;
    // background: #fff3f3;
}

.subpage-text-inactive {
    color: grey!important;
}

.subpage-icon-active {
    color: #7367f0!important;
}

.subpage-disabled {
    background: #ececec;
    cursor: not-allowed!important;
}

.product-variant-btn {
    width: 100%;
    height: 40px;
    color: #7367f0;
    border: 1px dashed #7367f0
}

.product-variant-btn:hover {
    color: #9e95f5;
    background-color: #f5fbff;
}

.user-detail-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-body {
    &.role-card {
        padding: 1rem;
    }
}

.option-delete-trash {
    display: flex;
    align-items: center;
    padding-left: 3px;
}

.jumbotron {
    background-color: white;
    border-style: dotted;
    border-width: thin;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-product-text {
    text-align: right;
}

// product category
.product-text-active {
    // font-weight: 700;
    color: #ed3135!important;
}

.product-text-inactive {
    color: black!important;
}

// retail category modal
.category-option {
    background-color:#ffffff;
    overflow-y: scroll;
    max-height: 250px;
    // cursor: pointer;
}

.category-div {
    padding: 5px;
    cursor: pointer;
}

.category-div:hover {
    background-color: #f5f5f5;
}

// react-autocomplete
  
.react-autosuggest__container {
    position: relative;
}
  
.react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}
  
.react-autosuggest__input:focus {
    outline: none;
}
  
.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
  
.react-autosuggest__suggestions-container {
    display: none;
}
  
.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    // top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}
  
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
  
.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}
  
.react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
}
  
.react-autosuggest__suggestion--focused {
    background-color: #0C7EAF;
    color: #fff;
}
  
.suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}
    
.name {
    margin-left: 68px;
    line-height: 45px;
}
  
.highlight {
    color: #ee0000;
    font-weight: bold;
}
  
.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}

.tox .tox-statusbar__text-container {
    display: none !important;
}

.tox .tox-statusbar__path {
    display: none;
}

.tox .tox-statusbar {
    border-top: none;
    // border-top : 1px solid transparent
}

.tox .tox-statusbar__branding {
    display: none;
}

.tox .tox-statusbar__path-item {
    display: none;
}

// invoice print
@media print {
    .no-print {
        display: none;
    }
}

.dash-title {
    @media (max-width: 576px) {
        display: flex;
        .dash-sub {
            width: 100%;
            text-align: end;
        }
    }
}

.dash-sub {
    width: max-content;
}

.no-banner-div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
}

.order-avatar {
    .avatar{
        img{
            object-fit: cover;
        }
    }
}

.icon-avatar {
    cursor: auto;
}

.nav-tabs {
    margin-top: 1rem;
}

.submit-section {
    display: contents;
}

.apply-all-col {
    background-color: #F5F5F6;
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1rem;
    margin-bottom: 0.75rem;
}

.apply-all-field {
    width: 30%;
    @media (max-width: 576px) {
        width: 100%;
    }
}

.sitemap-url {
    color: #7367f0;
    cursor: pointer;
    font-weight: bold;
}

.custom-error-message {
    margin-top: 0.25rem;
    width: 100%;
    color: #eb2041;
    font-size: 0.857rem;
}

.large-image-div-style {
    display: flex;
    height: 300px;
    width: auto;
}

.flex-no-wrap {
	flex-wrap: nowrap !important;
}
li.tab-min-width-fit {
	min-width: fit-content !important;
}

.form-control {
    &.category-modal:read-only {
        opacity: 1;
    }
}

.custom-is-invalid {
    border-color: #eb2041 !important;
}