.wyswyg-div {
    h1 {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 0.5rem;
        color: #3d464d;
        line-height: 1.2;
    }
    h2 {
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 0.5rem;
        color: #3d464d;
        line-height: 1.2;
    }
    h3 {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 0.5rem;
        color: #3d464d;
        line-height: 1.2;
    }
    h4 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0.5rem;
        color: #3d464d;
        line-height: 1.2;
    }
    h5 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0.5rem;
        color: #3d464d;
        line-height: 1.2;
    }
    h6 {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0.5rem;
        color: #3d464d;
        line-height: 1.2;
    }

    .editable-area blockquote,
    .editable-area h1,
    .editable-area h2,
    .editable-area h3,
    .editable-area h4,
    .editable-area h5,
    .editable-area h6,
    .editable-area p,
    .editable-area pre {
        margin: 1em 0 0.5em;
    }

    .editable-area blockquote:first-child,
    .editable-area h1:first-child,
    .editable-area h2:first-child,
    .editable-area h3:first-child,
    .editable-area h4:first-child,
    .editable-area h5:first-child,
    .editable-area h6:first-child,
    .editable-area p:first-child,
    .editable-area pre:first-child {
        margin-top: 0;
    }

    .editable-area blockquote:last-child,
    .editable-area h1:last-child,
    .editable-area h2:last-child,
    .editable-area h3:last-child,
    .editable-area h4:last-child,
    .editable-area h5:last-child,
    .editable-area h6:last-child,
    .editable-area p:last-child,
    .editable-area pre:last-child {
        margin-bottom: 0;
    }

    .editable-area ol,
    .editable-area ul {
        margin: 0;
    }

    .editable-area li {
        margin: 1em 0;
    }

    .editable-area body {
        font-size: 1em;
        line-height: 1.25em;
    }

    .editable-area em {
        font-style: italic;
    }

    .editable-area strong {
        font-weight: 700;
    }

    .editable-area h1 strong,
    .editable-area h2 strong,
    .editable-area h3 strong,
    .editable-area h4 strong,
    .editable-area h5 strong,
    .editable-area h6 strong {
        font-weight: 800;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area body {
            font-size: 1em;
            line-height: 1.375em;
        }
    }

    .editable-area h1 {
        margin: 1em 0 0.5em;
        font-size: 2em;
        line-height: 1.25em;
    }

    .editable-area h1:first-child {
        margin-top: 0;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area h1 {
            font-size: 2.5em;
            line-height: 1.125;
            font-size: 3em;
            line-height: 1.05;
        }
    }

    .editable-area h2 {
        margin: 0.8em 0 0.5em;
        font-size: 1.625em;
        line-height: 1.15384615;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area h2 {
            font-size: 2em;
            line-height: 1.25em;
        }
    }

    @media only screen and (max-width: 48em) {
        .editable-area h2 {
            font-size: 2.25em;
            line-height: 1.25em;
        }
    }

    .editable-area h3 {
        margin: 0.8em 0 0.5em;
        font-size: 1.375em;
        line-height: 1.13636364;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area h3 {
            font-size: 1.5em;
            line-height: 1.25em;
        }
    }

    @media only screen and (max-width: 48em) {
        .editable-area h3 {
            font-size: 1.75em;
            line-height: 1.25em;
        }
    }

    .editable-area h4 {
        margin: 0.8em 0 0.5em;
        font-size: 1.125em;
        line-height: 1.11111111;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area h4 {
            line-height: 1.25em;
        }
    }

    @media only screen and (max-width: 48em) {
        .editable-area h4 {
            line-height: 1.25em;
        }
    }

    .editable-area h5 {
        margin: 0.8em 0 0.5em;
        font-size: 1.085em;
        line-height: 1.055;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area h5 {
            line-height: 1.25em;
        }
    }

    @media only screen and (max-width: 48em) {
        .editable-area h5 {
            line-height: 1.25em;
        }
    }

    .editable-area h6 {
        margin: 0.8em 0 0.5em;
        font-size: 1.055em;
        line-height: 1.005;
    }

    @media only screen and (max-width: 64em) and (min-width: 48em) {
        .editable-area h6 {
            line-height: 1.25em;
        }
    }

    @media only screen and (max-width: 48em) {
        .editable-area h6 {
            line-height: 1.25em;
        }
    }

    .editable-area blockquote {
        font-size: 1em;
        font-style: italic;
        padding: 0 2.5em;
    }

    .react-page-plugins-content-divider {
        background-color: #aaa;
        width: 100%;
        height: 2px;
        border: none;
    }

    .editable-area code,
    .editable-area pre {
        font-family: monospace;
        border-radius: 0.3em;
        padding: 0.4em;
    }

    .editable-area code {
        display: inline;
        margin: 0 0.5em;
        white-space: pre;
    }

    .editable-area pre {
        display: block;
        margin: 1em;
    }

    .editable-area ol,
    .editable-area ul {
        margin: 1em 0;
        list-style-type: inside;
    }

    .editable-area li {
        margin: 0.2em 0 0.2em 1em;
    }

    .editable-area li p {
        margin: 0;
    }

    .editable-area ol {
        list-style-type: decimal;
    }

    .editable-area ul {
        list-style-type: disc;
    }

    body {
        margin: 0;
        color: #333;
        font-size: 18px;
        line-height: 28px;
        font-family: Open Sans, serif;
    }

    p {
        margin: 0 0 28px;
    }

    pre {
        overflow-x: auto;
    }

    a {
        color: #c73036;
        font-family: Georgia, serif;
    }

    a,
    a:hover {
        text-decoration: underline;
    }

    a:hover {
        color: #333;
    }

    .react-page-cell-inner-leaf {
        padding: 10px;
    }

    .react-page-editable {
        margin-right: auto;
        margin-left: auto;
        padding-right: 0;
        padding-right: var(--outer-margin, 2rem);
        padding-left: 0;
        padding-left: var(--outer-margin, 2rem);
    }

    .react-page-row {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex: 1 1;
        -moz-box-flex: 1;
        flex: 1 1;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: calc((0rem * 0.5) * -1);
        margin-right: var(--gutter-compensation, -0.5rem);
        margin-left: calc((0rem * 0.5) * -1);
        margin-left: var(--gutter-compensation, -0.5rem);
        position: relative;
    }

    .react-page-cell-xs,
    .react-page-cell-xs-1,
    .react-page-cell-xs-2,
    .react-page-cell-xs-3,
    .react-page-cell-xs-4,
    .react-page-cell-xs-5,
    .react-page-cell-xs-6,
    .react-page-cell-xs-7,
    .react-page-cell-xs-8,
    .react-page-cell-xs-9,
    .react-page-cell-xs-10,
    .react-page-cell-xs-11,
    .react-page-cell-xs-12,
    .react-page-cell-xs-offset-0,
    .react-page-cell-xs-offset-1,
    .react-page-cell-xs-offset-2,
    .react-page-cell-xs-offset-3,
    .react-page-cell-xs-offset-4,
    .react-page-cell-xs-offset-5,
    .react-page-cell-xs-offset-6,
    .react-page-cell-xs-offset-7,
    .react-page-cell-xs-offset-8,
    .react-page-cell-xs-offset-9,
    .react-page-cell-xs-offset-10,
    .react-page-cell-xs-offset-11,
    .react-page-cell-xs-offset-12 {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        flex: 0 0 auto;
        padding-right: calc((0rem * 0.5));
        padding-right: var(--half-gutter-width, 0.5rem);
        padding-left: calc((0rem * 0.5));
        padding-left: var(--half-gutter-width, 0.5rem);
        min-width: 0;
    }

    .react-page-cell-xs {
        -webkit-flex-grow: 1;
        -moz-box-flex: 1;
        flex-grow: 1;
        -webkit-flex-basis: 0;
        flex-basis: 0;
        width: 100%;
        min-width: 100%;
    }

    .react-page-cell-xs-1 {
        -webkit-flex-basis: 8.33333333%;
        flex-basis: 8.33333333%;
        width: 8.33333333%;
    }

    .react-page-cell-xs-2 {
        -webkit-flex-basis: 16.66666667%;
        flex-basis: 16.66666667%;
        width: 16.66666667%;
    }

    .react-page-cell-xs-3 {
        -webkit-flex-basis: 25%;
        flex-basis: 25%;
        width: 25%;
        min-width: 25%;
    }

    .react-page-cell-xs-4 {
        -webkit-flex-basis: 33.33333333%;
        flex-basis: 33.33333333%;
        width: 33.33333333%;
    }

    .react-page-cell-xs-5 {
        -webkit-flex-basis: 41.66666667%;
        flex-basis: 41.66666667%;
        width: 41.66666667%;
    }

    .react-page-cell-xs-6 {
        -webkit-flex-basis: 50%;
        flex-basis: 50%;
        width: 50%;
        min-width: 50%;
    }

    .react-page-cell-xs-7 {
        -webkit-flex-basis: 58.33333333%;
        flex-basis: 58.33333333%;
        width: 58.33333333%;
    }

    .react-page-cell-xs-8 {
        -webkit-flex-basis: 66.66666667%;
        flex-basis: 66.66666667%;
        width: 66.66666667%;
    }

    .react-page-cell-xs-9 {
        -webkit-flex-basis: 75%;
        flex-basis: 75%;
        width: 75%;
        min-width: 75%;
    }

    .react-page-cell-xs-10 {
        -webkit-flex-basis: 83.33333333%;
        flex-basis: 83.33333333%;
        width: 83.33333333%;
    }

    .react-page-cell-xs-11 {
        -webkit-flex-basis: 91.66666667%;
        flex-basis: 91.66666667%;
        width: 91.66666667%;
    }

    .react-page-cell-xs-12 {
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }

    .react-page-cell-xs-offset-0 {
        margin-left: 0;
    }

    .react-page-cell-xs-offset-1 {
        margin-left: 8.33333333%;
    }

    .react-page-cell-xs-offset-2 {
        margin-left: 16.66666667%;
    }

    .react-page-cell-xs-offset-3 {
        margin-left: 25%;
    }

    .react-page-cell-xs-offset-4 {
        margin-left: 33.33333333%;
    }

    .react-page-cell-xs-offset-5 {
        margin-left: 41.66666667%;
    }

    .react-page-cell-xs-offset-6 {
        margin-left: 50%;
    }

    .react-page-cell-xs-offset-7 {
        margin-left: 58.33333333%;
    }

    .react-page-cell-xs-offset-8 {
        margin-left: 66.66666667%;
    }

    .react-page-cell-xs-offset-9 {
        margin-left: 75%;
    }

    .react-page-cell-xs-offset-10 {
        margin-left: 83.33333333%;
    }

    .react-page-cell-xs-offset-11 {
        margin-left: 91.66666667%;
    }

    [dir="ltr"] .start-xs {
        text-align: left;
    }

    [dir="rtl"] .start-xs {
        text-align: right;
    }

    .start-xs {
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        justify-content: flex-start;
    }

    .center-xs {
        -webkit-justify-content: center;
        -moz-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    [dir="ltr"] .end-xs {
        text-align: right;
    }

    [dir="rtl"] .end-xs {
        text-align: left;
    }

    .end-xs {
        -webkit-justify-content: flex-end;
        -moz-box-pack: end;
        justify-content: flex-end;
    }

    .top-xs {
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        align-items: flex-start;
    }

    .middle-xs {
        -webkit-align-items: center;
        -moz-box-align: center;
        align-items: center;
    }

    .bottom-xs {
        -webkit-align-items: flex-end;
        -moz-box-align: end;
        align-items: flex-end;
    }

    .around-xs {
        -webkit-justify-content: space-around;
        justify-content: space-around;
    }

    .between-xs {
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        justify-content: space-between;
    }

    .first-xs {
        -webkit-order: -1;
        -moz-box-ordinal-group: 0;
        order: -1;
    }

    .last-xs {
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        order: 1;
    }

    @media only screen and (min-width: 48em) {
        .react-page-cell-sm,
        .react-page-cell-sm-1,
        .react-page-cell-sm-2,
        .react-page-cell-sm-3,
        .react-page-cell-sm-4,
        .react-page-cell-sm-5,
        .react-page-cell-sm-6,
        .react-page-cell-sm-7,
        .react-page-cell-sm-8,
        .react-page-cell-sm-9,
        .react-page-cell-sm-10,
        .react-page-cell-sm-11,
        .react-page-cell-sm-12,
        .react-page-cell-sm-offset-0,
        .react-page-cell-sm-offset-1,
        .react-page-cell-sm-offset-2,
        .react-page-cell-sm-offset-3,
        .react-page-cell-sm-offset-4,
        .react-page-cell-sm-offset-5,
        .react-page-cell-sm-offset-6,
        .react-page-cell-sm-offset-7,
        .react-page-cell-sm-offset-8,
        .react-page-cell-sm-offset-9,
        .react-page-cell-sm-offset-10,
        .react-page-cell-sm-offset-11,
        .react-page-cell-sm-offset-12 {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-flex: 0 0 auto;
            -moz-box-flex: 0;
            flex: 0 0 auto;
            padding-right: calc((0rem * 0.5));
            padding-right: var(--half-gutter-width, 0.5rem);
            padding-left: calc((0rem * 0.5));
            padding-left: var(--half-gutter-width, 0.5rem);
            min-width: 0;
        }

        .react-page-cell-sm {
            -webkit-flex-grow: 1;
            -moz-box-flex: 1;
            flex-grow: 1;
            -webkit-flex-basis: 0;
            flex-basis: 0;
            width: 100%;
            min-width: 100%;
        }

        .react-page-cell-sm-1 {
            -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
            width: 8.33333333%;
        }

        .react-page-cell-sm-2 {
            -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
            width: 16.66666667%;
        }

        .react-page-cell-sm-3 {
            -webkit-flex-basis: 25%;
            flex-basis: 25%;
            width: 25%;
            min-width: 25%;
        }

        .react-page-cell-sm-4 {
            -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
            width: 33.33333333%;
        }

        .react-page-cell-sm-5 {
            -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
            width: 41.66666667%;
        }

        .react-page-cell-sm-6 {
            -webkit-flex-basis: 50%;
            flex-basis: 50%;
            width: 50%;
            min-width: 50%;
        }

        .react-page-cell-sm-7 {
            -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
            width: 58.33333333%;
        }

        .react-page-cell-sm-8 {
            -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
            width: 66.66666667%;
        }

        .react-page-cell-sm-9 {
            -webkit-flex-basis: 75%;
            flex-basis: 75%;
            width: 75%;
            min-width: 75%;
        }

        .react-page-cell-sm-10 {
            -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
            width: 83.33333333%;
        }

        .react-page-cell-sm-11 {
            -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
            width: 91.66666667%;
        }

        .react-page-cell-sm-12 {
            -webkit-flex-basis: 100%;
            flex-basis: 100%;
            width: 100%;
            min-width: 100%;
        }

        .react-page-cell-sm-offset-0 {
            margin-left: 0;
        }

        .react-page-cell-sm-offset-1 {
            margin-left: 8.33333333%;
        }

        .react-page-cell-sm-offset-2 {
            margin-left: 16.66666667%;
        }

        .react-page-cell-sm-offset-3 {
            margin-left: 25%;
        }

        .react-page-cell-sm-offset-4 {
            margin-left: 33.33333333%;
        }

        .react-page-cell-sm-offset-5 {
            margin-left: 41.66666667%;
        }

        .react-page-cell-sm-offset-6 {
            margin-left: 50%;
        }

        .react-page-cell-sm-offset-7 {
            margin-left: 58.33333333%;
        }

        .react-page-cell-sm-offset-8 {
            margin-left: 66.66666667%;
        }

        .react-page-cell-sm-offset-9 {
            margin-left: 75%;
        }

        .react-page-cell-sm-offset-10 {
            margin-left: 83.33333333%;
        }

        .react-page-cell-sm-offset-11 {
            margin-left: 91.66666667%;
        }

        [dir="ltr"] .start-sm {
            text-align: left;
        }

        [dir="rtl"] .start-sm {
            text-align: right;
        }

        .start-sm {
            -webkit-justify-content: flex-start;
            -moz-box-pack: start;
            justify-content: flex-start;
        }

        .center-sm {
            -webkit-justify-content: center;
            -moz-box-pack: center;
            justify-content: center;
            text-align: center;
        }

        [dir="ltr"] .end-sm {
            text-align: right;
        }

        [dir="rtl"] .end-sm {
            text-align: left;
        }

        .end-sm {
            -webkit-justify-content: flex-end;
            -moz-box-pack: end;
            justify-content: flex-end;
        }

        .top-sm {
            -webkit-align-items: flex-start;
            -moz-box-align: start;
            align-items: flex-start;
        }

        .middle-sm {
            -webkit-align-items: center;
            -moz-box-align: center;
            align-items: center;
        }

        .bottom-sm {
            -webkit-align-items: flex-end;
            -moz-box-align: end;
            align-items: flex-end;
        }

        .around-sm {
            -webkit-justify-content: space-around;
            justify-content: space-around;
        }

        .between-sm {
            -webkit-justify-content: space-between;
            -moz-box-pack: justify;
            justify-content: space-between;
        }

        .first-sm {
            -webkit-order: -1;
            -moz-box-ordinal-group: 0;
            order: -1;
        }

        .last-sm {
            -webkit-order: 1;
            -moz-box-ordinal-group: 2;
            order: 1;
        }
    }

    @media only screen and (min-width: 64em) {
        .react-page-cell-md,
        .react-page-cell-md-1,
        .react-page-cell-md-2,
        .react-page-cell-md-3,
        .react-page-cell-md-4,
        .react-page-cell-md-5,
        .react-page-cell-md-6,
        .react-page-cell-md-7,
        .react-page-cell-md-8,
        .react-page-cell-md-9,
        .react-page-cell-md-10,
        .react-page-cell-md-11,
        .react-page-cell-md-12,
        .react-page-cell-md-offset-0,
        .react-page-cell-md-offset-1,
        .react-page-cell-md-offset-2,
        .react-page-cell-md-offset-3,
        .react-page-cell-md-offset-4,
        .react-page-cell-md-offset-5,
        .react-page-cell-md-offset-6,
        .react-page-cell-md-offset-7,
        .react-page-cell-md-offset-8,
        .react-page-cell-md-offset-9,
        .react-page-cell-md-offset-10,
        .react-page-cell-md-offset-11,
        .react-page-cell-md-offset-12 {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-flex: 0 0 auto;
            -moz-box-flex: 0;
            flex: 0 0 auto;
            padding-right: calc((0rem * 0.5));
            padding-right: var(--half-gutter-width, 0.5rem);
            padding-left: calc((0rem * 0.5));
            padding-left: var(--half-gutter-width, 0.5rem);
            min-width: 0;
        }

        .react-page-cell-md {
            -webkit-flex-grow: 1;
            -moz-box-flex: 1;
            flex-grow: 1;
            -webkit-flex-basis: 0;
            flex-basis: 0;
            width: 100%;
            min-width: 100%;
        }

        .react-page-cell-md-1 {
            -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
            width: 8.33333333%;
        }

        .react-page-cell-md-2 {
            -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
            width: 16.66666667%;
        }

        .react-page-cell-md-3 {
            -webkit-flex-basis: 25%;
            flex-basis: 25%;
            width: 25%;
            min-width: 25%;
        }

        .react-page-cell-md-4 {
            -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
            width: 33.33333333%;
        }

        .react-page-cell-md-5 {
            -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
            width: 41.66666667%;
        }

        .react-page-cell-md-6 {
            -webkit-flex-basis: 50%;
            flex-basis: 50%;
            width: 50%;
            min-width: 50%;
        }

        .react-page-cell-md-7 {
            -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
            width: 58.33333333%;
        }

        .react-page-cell-md-8 {
            -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
            width: 66.66666667%;
        }

        .react-page-cell-md-9 {
            -webkit-flex-basis: 75%;
            flex-basis: 75%;
            width: 75%;
            min-width: 75%;
        }

        .react-page-cell-md-10 {
            -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
            width: 83.33333333%;
        }

        .react-page-cell-md-11 {
            -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
            width: 91.66666667%;
        }

        .react-page-cell-md-12 {
            -webkit-flex-basis: 100%;
            flex-basis: 100%;
            width: 100%;
            min-width: 100%;
        }

        .react-page-cell-md-offset-0 {
            margin-left: 0;
        }

        .react-page-cell-md-offset-1 {
            margin-left: 8.33333333%;
        }

        .react-page-cell-md-offset-2 {
            margin-left: 16.66666667%;
        }

        .react-page-cell-md-offset-3 {
            margin-left: 25%;
        }

        .react-page-cell-md-offset-4 {
            margin-left: 33.33333333%;
        }

        .react-page-cell-md-offset-5 {
            margin-left: 41.66666667%;
        }

        .react-page-cell-md-offset-6 {
            margin-left: 50%;
        }

        .react-page-cell-md-offset-7 {
            margin-left: 58.33333333%;
        }

        .react-page-cell-md-offset-8 {
            margin-left: 66.66666667%;
        }

        .react-page-cell-md-offset-9 {
            margin-left: 75%;
        }

        .react-page-cell-md-offset-10 {
            margin-left: 83.33333333%;
        }

        .react-page-cell-md-offset-11 {
            margin-left: 91.66666667%;
        }

        [dir="ltr"] .start-md {
            text-align: left;
        }

        [dir="rtl"] .start-md {
            text-align: right;
        }

        .start-md {
            -webkit-justify-content: flex-start;
            -moz-box-pack: start;
            justify-content: flex-start;
        }

        .center-md {
            -webkit-justify-content: center;
            -moz-box-pack: center;
            justify-content: center;
            text-align: center;
        }

        [dir="ltr"] .end-md {
            text-align: right;
        }

        [dir="rtl"] .end-md {
            text-align: left;
        }

        .end-md {
            -webkit-justify-content: flex-end;
            -moz-box-pack: end;
            justify-content: flex-end;
        }

        .top-md {
            -webkit-align-items: flex-start;
            -moz-box-align: start;
            align-items: flex-start;
        }

        .middle-md {
            -webkit-align-items: center;
            -moz-box-align: center;
            align-items: center;
        }

        .bottom-md {
            -webkit-align-items: flex-end;
            -moz-box-align: end;
            align-items: flex-end;
        }

        .around-md {
            -webkit-justify-content: space-around;
            justify-content: space-around;
        }

        .between-md {
            -webkit-justify-content: space-between;
            -moz-box-pack: justify;
            justify-content: space-between;
        }

        .first-md {
            -webkit-order: -1;
            -moz-box-ordinal-group: 0;
            order: -1;
        }

        .last-md {
            -webkit-order: 1;
            -moz-box-ordinal-group: 2;
            order: 1;
        }
    }

    @media only screen and (min-width: 75em) {
        .react-page-cell-lg,
        .react-page-cell-lg-1,
        .react-page-cell-lg-2,
        .react-page-cell-lg-3,
        .react-page-cell-lg-4,
        .react-page-cell-lg-5,
        .react-page-cell-lg-6,
        .react-page-cell-lg-7,
        .react-page-cell-lg-8,
        .react-page-cell-lg-9,
        .react-page-cell-lg-10,
        .react-page-cell-lg-11,
        .react-page-cell-lg-12,
        .react-page-cell-lg-offset-0,
        .react-page-cell-lg-offset-1,
        .react-page-cell-lg-offset-2,
        .react-page-cell-lg-offset-3,
        .react-page-cell-lg-offset-4,
        .react-page-cell-lg-offset-5,
        .react-page-cell-lg-offset-6,
        .react-page-cell-lg-offset-7,
        .react-page-cell-lg-offset-8,
        .react-page-cell-lg-offset-9,
        .react-page-cell-lg-offset-10,
        .react-page-cell-lg-offset-11,
        .react-page-cell-lg-offset-12 {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-flex: 0 0 auto;
            -moz-box-flex: 0;
            flex: 0 0 auto;
            padding-right: calc((0rem * 0.5));
            padding-right: var(--half-gutter-width, 0.5rem);
            padding-left: calc((0rem * 0.5));
            padding-left: var(--half-gutter-width, 0.5rem);
            min-width: 0;
        }

        .react-page-cell-lg {
            -webkit-flex-grow: 1;
            -moz-box-flex: 1;
            flex-grow: 1;
            -webkit-flex-basis: 0;
            flex-basis: 0;
            width: 100%;
            min-width: 100%;
        }

        .react-page-cell-lg-1 {
            -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
            width: 8.33333333%;
        }

        .react-page-cell-lg-2 {
            -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
            width: 16.66666667%;
        }

        .react-page-cell-lg-3 {
            -webkit-flex-basis: 25%;
            flex-basis: 25%;
            width: 25%;
            min-width: 25%;
        }

        .react-page-cell-lg-4 {
            -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
            width: 33.33333333%;
        }

        .react-page-cell-lg-5 {
            -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
            width: 41.66666667%;
        }

        .react-page-cell-lg-6 {
            -webkit-flex-basis: 50%;
            flex-basis: 50%;
            width: 50%;
            min-width: 50%;
        }

        .react-page-cell-lg-7 {
            -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
            width: 58.33333333%;
        }

        .react-page-cell-lg-8 {
            -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
            width: 66.66666667%;
        }

        .react-page-cell-lg-9 {
            -webkit-flex-basis: 75%;
            flex-basis: 75%;
            width: 75%;
            min-width: 75%;
        }

        .react-page-cell-lg-10 {
            -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
            width: 83.33333333%;
        }

        .react-page-cell-lg-11 {
            -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
            width: 91.66666667%;
        }

        .react-page-cell-lg-12 {
            -webkit-flex-basis: 100%;
            flex-basis: 100%;
            width: 100%;
            min-width: 100%;
        }

        .react-page-cell-lg-offset-0 {
            margin-left: 0;
        }

        .react-page-cell-lg-offset-1 {
            margin-left: 8.33333333%;
        }

        .react-page-cell-lg-offset-2 {
            margin-left: 16.66666667%;
        }

        .react-page-cell-lg-offset-3 {
            margin-left: 25%;
        }

        .react-page-cell-lg-offset-4 {
            margin-left: 33.33333333%;
        }

        .react-page-cell-lg-offset-5 {
            margin-left: 41.66666667%;
        }

        .react-page-cell-lg-offset-6 {
            margin-left: 50%;
        }

        .react-page-cell-lg-offset-7 {
            margin-left: 58.33333333%;
        }

        .react-page-cell-lg-offset-8 {
            margin-left: 66.66666667%;
        }

        .react-page-cell-lg-offset-9 {
            margin-left: 75%;
        }

        .react-page-cell-lg-offset-10 {
            margin-left: 83.33333333%;
        }

        .react-page-cell-lg-offset-11 {
            margin-left: 91.66666667%;
        }

        [dir="ltr"] .start-lg {
            text-align: left;
        }

        [dir="rtl"] .start-lg {
            text-align: right;
        }

        .start-lg {
            -webkit-justify-content: flex-start;
            -moz-box-pack: start;
            justify-content: flex-start;
        }

        .center-lg {
            -webkit-justify-content: center;
            -moz-box-pack: center;
            justify-content: center;
            text-align: center;
        }

        [dir="ltr"] .end-lg {
            text-align: right;
        }

        [dir="rtl"] .end-lg {
            text-align: left;
        }

        .end-lg {
            -webkit-justify-content: flex-end;
            -moz-box-pack: end;
            justify-content: flex-end;
        }

        .top-lg {
            -webkit-align-items: flex-start;
            -moz-box-align: start;
            align-items: flex-start;
        }

        .middle-lg {
            -webkit-align-items: center;
            -moz-box-align: center;
            align-items: center;
        }

        .bottom-lg {
            -webkit-align-items: flex-end;
            -moz-box-align: end;
            align-items: flex-end;
        }

        .around-lg {
            -webkit-justify-content: space-around;
            justify-content: space-around;
        }

        .between-lg {
            -webkit-justify-content: space-between;
            -moz-box-pack: justify;
            justify-content: space-between;
        }

        .first-lg {
            -webkit-order: -1;
            -moz-box-ordinal-group: 0;
            order: -1;
        }

        .last-lg {
            -webkit-order: 1;
            -moz-box-ordinal-group: 2;
            order: 1;
        }
    }

    .react-page-row-has-floating-children {
        display: block !important;
    }

    .react-page-row-is-hovering-this:before {
        content: " ";
        position: absolute;
        background: #000;
        display: block;
        background: rgba(0, 0, 0, 0.87);
        background: var(--darkBlack);
        z-index: 2;
    }

    .react-page-row-is-hovering-above:before {
        top: 0;
        height: 4px;
        width: 100%;
    }

    .react-page-row-is-hovering-below:before {
        bottom: 0;
        height: 4px;
        width: 100%;
    }

    .react-page-row-is-hovering-left-of:before {
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
    }

    .react-page-row-is-hovering-right-of:before {
        top: 0;
        right: 0;
        bottom: 0;
        width: 4px;
    }

    @-webkit-keyframes reactPageCellResizeableAnimate {
        0% {
            background-color: hsla(0, 0%, 100%, 0.26);
            background-color: var(--minWhite);
            top: 45%;
            bottom: 45%;
        }

        50% {
            background-color: hsla(0, 0%, 100%, 0.12);
            background-color: var(--faintWhite);
            top: 0;
            bottom: 0;
        }

        to {
            background-color: hsla(0, 0%, 100%, 0.87);
            background-color: var(--darkWhite);
            top: 45%;
            bottom: 45%;
        }
    }

    @keyframes reactPageCellResizeableAnimate {
        0% {
            background-color: hsla(0, 0%, 100%, 0.26);
            background-color: var(--minWhite);
            top: 45%;
            bottom: 45%;
        }

        50% {
            background-color: hsla(0, 0%, 100%, 0.12);
            background-color: var(--faintWhite);
            top: 0;
            bottom: 0;
        }

        to {
            background-color: hsla(0, 0%, 100%, 0.87);
            background-color: var(--darkWhite);
            top: 45%;
            bottom: 45%;
        }
    }

    .react-page-row .resize-handle {
        position: absolute;
        z-index: 6;
        width: 4px;
        top: 0;
        bottom: 0;
        left: -2px;
        cursor: e-resize;
        background-color: rgba(0, 0, 0, 0.54);
        background-color: var(--lightBlack);
        mix-blend-mode: difference;
    }

    .react-page-row .resize-handle:after {
        content: " ";
        background-color: hsla(0, 0%, 100%, 0.87);
        background-color: var(--darkWhite);
        position: absolute;
        top: 40%;
        bottom: 40%;
        left: 0;
        right: 0;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        mix-blend-mode: normal;
        -webkit-animation-name: reactPageCellResizeableAnimate;
        animation-name: reactPageCellResizeableAnimate;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
        -webkit-animation-duration: 0.8s;
        animation-duration: 0.8s;
    }

    .react-page-row .resize-handle:active:after,
    .react-page-row .resize-handle:hover:after {
        background-color: #fff;
        background-color: var(--fullWhite);
        top: 0;
        bottom: 0;
    }

    .react-page-editable {
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        min-height: 480px;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex: 0 1 auto;
        -moz-box-flex: 0;
        flex: 0 1 auto;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        -webkit-align-items: stretch;
        -moz-box-align: stretch;
        align-items: stretch;
    }

    .react-page-editable .react-page-cell-insert-new {
        cursor: cell;
        -webkit-flex: 1 1;
        -moz-box-flex: 1;
        flex: 1 1;
        min-height: 100px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        align-items: center;
        border: 1px solid transparent;
        z-index: 1;
        position: relative;
    }

    // .react-page-editable .react-page-cell-insert-new:after {
    //     content: "+";
    //     font-size: 52px;
    //     font-family: monospace;
    //     line-height: 52px;
    //     background-color: rgba(0, 0, 0, 0.26);
    //     background-color: var(--minBlack);
    //     color: #fff;
    //     width: 52px;
    //     text-align: center;
    //     border-radius: 50%;
    // }

    .react-page-editable .react-page-cell-insert-new.hover,
    .react-page-editable .react-page-cell-insert-new:hover {
        background: rgba(0, 0, 0, 0.26);
        background: var(--minBlack);
        border: 1px solid hsla(0, 0%, 100%, 0.54);
        border: 1px solid var(--lightWhite);
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable {
        cursor: move;
        position: relative;
        -webkit-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: move;
        border: 1px solid transparent;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        cursor: move;
        background: rgba(0, 0, 0, 0.12);
        background: var(--faintBlack);
        content: "";
        z-index: 500;
        opacity: 0;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell.react-page-cell-focused
        > div
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell:hover
        > div
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell.react-page-cell-focused
        > div
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell:hover
        > div
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell.react-page-cell-focused
        > div
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell:hover
        > div
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay-handle {
        opacity: 1;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before {
        top: -300%;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon {
        width: 70%;
        top: 50%;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after {
        top: 400%;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before {
        width: 100%;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:after,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay-handle
        .react-page-cell-draggable-overlay-handle-icon:before {
        height: 2px;
        position: absolute;
        content: "";
        -webkit-transform: translate(-50%, -50%);
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell:hover
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell:hover
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell:hover
        > div
        > div
        > div
        > .react-page-cell-draggable-overlay {
        background: rgba(0, 0, 0, 0.12);
        background: var(--faintBlack);
        border: 1px solid hsla(0, 0%, 100%, 0.54);
        border: 1px solid var(--lightWhite);
        background-blend-mode: screen;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf {
        z-index: 3;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay {
        float: left;
        z-index: 4;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-is-dragging,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-handle-is-dragging
        + div
        > .react-page-cell-droppable
        > .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell-handle-is-dragging
        + div
        > .react-page-cell-droppable
        > .react-page-cell-draggable-in-edit,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-is-dragging,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-handle-is-dragging
        + div
        > .react-page-cell-droppable
        > .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell-handle-is-dragging
        + div
        > .react-page-cell-droppable
        > .react-page-cell-draggable-in-edit,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-is-dragging,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-handle-is-dragging
        + div
        > .react-page-cell-droppable
        > .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell-handle-is-dragging
        + div
        > .react-page-cell-droppable
        > .react-page-cell-draggable-in-edit {
        opacity: 0.4;
        outline: none;
    }

    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .react-page-editable.react-page-editable-mode-layout .react-page-cell-move-actions {
        opacity: 0;
        -webkit-transition: 0.6s;
        transition: 0.6s;
    }

    .react-page-editable.react-page-editable-mode-layout .react-page-cell:hover > .react-page-cell-move-actions {
        opacity: 0.8;
    }

    .react-page-cell-droppable-is-over-current:after {
        content: "";
        height: 100px;
        width: 100%;
        position: absolute;
        bottom: -100px;
    }

    .react-page-cell-droppable-is-over-current:before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 1;
        background: rgba(0, 0, 0, 0.87);
        background: var(--darkBlack);
    }

    .react-page-cell-droppable-is-over-above:before,
    .react-page-cell-droppable-is-over-below:before {
        border-left: 0;
        border-right: 0;
    }

    .react-page-cell-droppable-is-over-left-of:before,
    .react-page-cell-droppable-is-over-right-of:before {
        border-top: 0;
        border-bottom: 0;
    }

    .react-page-cell-droppable-is-over-above:before {
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        border-left: 0;
        border-right: 0;
    }

    .react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-above:before {
        height: 4px;
    }

    .react-page-cell-droppable-is-over-below:before {
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
    }

    .react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-below:before {
        height: 4px;
    }

    .react-page-cell-droppable-is-over-left-of:before {
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
    }

    .react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-left-of:before {
        width: 4px;
    }

    .react-page-cell-droppable-is-over-right-of:before {
        top: 0;
        right: 0;
        bottom: 0;
        width: 4px;
    }

    .react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-right-of:before {
        width: 4px;
    }

    .react-page-cell-droppable-is-over-inline-left:before,
    .react-page-cell-droppable-is-over-inline-right:before {
        top: 0;
        width: 50%;
        height: 36%;
        max-height: 128px;
        background-color: #e0e0e0;
        background-color: var(--grey300);
        border: 1px solid rgba(0, 0, 0, 0.26);
        border: 1px solid var(--minBlack);
    }

    .react-page-cell-droppable-is-over-inline-left:before {
        left: 0;
    }

    .react-page-cell-droppable-is-over-inline-right:before {
        right: 0;
    }

    .react-page-cell-droppable-not-allowed {
        cursor: not-allowed;
    }

    .react-page-cell-error {
        background-color: red;
        padding: 8px;
        margin: 2px;
        overflow: hidden;
    }

    .react-page-cell-error strong {
        margin: 0 auto;
    }

    .react-page-cell-error code {
        overflow: scroll;
    }

    .react-page-editable .react-page-cell-handle {
        display: none;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-100%);
        transform: translateX(-50%) translateY(-100%);
        -webkit-transition: opacity 0.4s ease;
        transition: opacity 0.4s ease;
        opacity: 0;
        background: hsla(0, 0%, 100%, 0.95);
        text-align: center;
        color: rgba(0, 0, 0, 0.97);
        display: inline-block;
        padding: 12px 24px;
        margin: 0 auto;
        border-radius: 12px 12px 0 0;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: 0.15em;
        box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.22);
        pointer-events: none;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-drag-enabled,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-drag-enabled,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-drag-enabled {
        cursor: move;
    }

    .react-page-editable.react-page-editable-mode-edit
        .react-page-cell.react-page-cell-focused
        > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell:hover > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell.react-page-cell-focused
        > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell:hover > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell.react-page-cell-focused
        > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell:hover > .react-page-cell-handle {
        opacity: 1;
        pointer-events: all;
    }

    .react-page-cell {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .react-page-cell-inline-left {
        float: left;
    }

    .react-page-cell-inline-right {
        float: right;
    }

    .react-page-cell-has-inline-neighbour {
        float: none;
        width: 100%;
        display: block;
    }

    .react-page-cell .react-page-cell-inner {
        height: 100%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell {
        position: relative;
        -webkit-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-is-draft,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-is-draft,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-is-draft {
        opacity: 0.3;
        outline: 1px dashed #000;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell-bring-to-front,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-bring-to-front,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-bring-to-front {
        z-index: 3;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-has-plugin:hover,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-has-plugin:hover,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-has-plugin:hover {
        z-index: 2;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        opacity: 1;
    }

    .react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused.react-page-cell-focused,
    .react-page-editable.react-page-editable-mode-layout
        .react-page-cell.react-page-cell-focused.react-page-cell-focused,
    .react-page-editable.react-page-editable-mode-resizing
        .react-page-cell.react-page-cell-focused.react-page-cell-focused {
        z-index: 1;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
        opacity: 1;
    }

    .react-page-editable.react-page-editable-mode-insert .react-page-cell {
        cursor: cell;
    }

    .react-page-editable.react-page-editable-mode-insert .react-page-cell:hover {
        z-index: 1;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
        opacity: 1;
    }

    .react-page-controls-mode-toggle-button-inner {
        float: right;
        margin: 8px;
    }

    .react-page-controls-mode-toggle-button-description {
        font-size: 16px;
        margin-top: 18px;
        float: right;
        background: transparent;
        color: transparent;
        border: 1px solid transparent;
        padding: 2px 8px;
        text-align: right;
        display: none;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        white-space: nowrap;
        overflow: hidden;
    }

    .react-page-controls-mode-toggle-button:hover .react-page-controls-mode-toggle-button-description {
        max-width: 999px;
        background: rgba(0, 0, 0, 0.87);
        background: var(--darkBlack);
        color: #fff;
        color: var(--white);
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border: 1px solid var(--faintBlack);
    }

    .react-page-controls-mode-toggle-clearfix {
        clear: both;
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        80% {
            opacity: 1;
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }

        to {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        80% {
            opacity: 1;
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }

        to {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    .react-page-controls-mode-toggle-control {
        z-index: 1;
        -webkit-animation: fadeIn 0.8s forwards;
        animation: fadeIn 0.8s forwards;
        opacity: 0;
    }

    .react-page-plugin-drawer-item {
        cursor: pointer;
        z-index: 1;
    }

    .react-page-plugin-drawer-item:hover {
        z-index: 2;
        box-shadow: 0 0 20px #ccc;
    }

    .react-page-controls-trash {
        position: fixed;
        bottom: -64px;
        z-index: 500;
        left: 50%;
        -webkit-transition: bottom 0.2s ease;
        transition: bottom 0.2s ease;
        padding: 8px;
    }

    .react-page-controls-trash.react-page-controls-trash-active {
        bottom: 16px;
    }

    .react-page-plugins-layout-background {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        padding: 12px;
        position: relative;
    }

    .react-page-plugins-layout-background > .react-page-row {
        position: relative;
    }

    .react-page-plugins-layout-background__backstretch {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .react-page-plugins-content-spacer {
        border-bottom: none;
        position: relative;
    }

    .react-page-editable .react-page-plugins-content-spacer {
        outline: 1px dashed var(--minBlack);
    }

    .react-page-editable-mode-preview .react-page-plugins-content-spacer {
        outline: none;
    }

    .react-page-plugins-content-spacer > .react-resizable > .react-resizable-handle:before,
    .react-page-plugins-content-spacer > .react-resizable > .react-resizable-handle:hover:before {
        content: " ";
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 0;
        right: 0;
        cursor: n-resize;
        line-height: 12px;
        font-size: 1.5em;
        height: 24px;
    }

    .react-page-plugins-content-video-placeholder {
        position: relative;
        width: 100%;
        text-align: center;
    }

    .react-page-plugins-content-image {
        width: 100%;
    }

    .react-page-plugins-content-image-placeholder {
        position: relative;
        width: 100%;
        text-align: center;
    }

    .react-page-plugins-content-slate-paragraph-placeholder {
        font-style: italic;
        color: var(--lightBlack);
    }

    .react-page-plugins-content-slate-inline-toolbar {
        position: absolute;
        z-index: 10;
        top: -10000px;
        left: -10000px;
        margin-top: -6px;
        opacity: 0;
        background-color: var(--grey900);
        border-radius: 4px;
        -webkit-transition: opacity 0.75s;
        transition: opacity 0.75s;
    }

    .react-page-plugins-content-slate-inline-toolbar--hidden {
        opacity: 0 !important;
        pointer-events: none;
    }

    .katex-display {
        display: block;
        margin: 1em 0;
        text-align: center;
    }

    .katex-display > .katex {
        display: inline-block;
        text-align: left;
        text-align: initial;
    }

    .katex {
        font: normal 1.21em KaTeX_Main, Times New Roman, serif;
        line-height: 1.2;
        white-space: nowrap;
        text-indent: 0;
        text-rendering: auto;
    }

    .katex * {
        -ms-high-contrast-adjust: none !important;
    }

    .katex .katex-html {
        display: inline-block;
    }

    .katex .katex-mathml {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }

    .katex .base {
        position: relative;
    }

    .katex .base,
    .katex .strut {
        display: inline-block;
    }

    .katex .textbf {
        font-weight: 700;
    }

    .katex .textit {
        font-style: italic;
    }

    .katex .textrm {
        font-family: KaTeX_Main;
    }

    .katex .textsf {
        font-family: KaTeX_SansSerif;
    }

    .katex .texttt {
        font-family: KaTeX_Typewriter;
    }

    .katex .mathit {
        font-family: KaTeX_Math;
        font-style: italic;
    }

    .katex .mathrm {
        font-style: normal;
    }

    .katex .mathbf {
        font-family: KaTeX_Main;
        font-weight: 700;
    }

    .katex .boldsymbol {
        font-family: KaTeX_Math;
        font-weight: 700;
        font-style: italic;
    }

    .katex .amsrm,
    .katex .mathbb {
        font-family: KaTeX_AMS;
    }

    .katex .mathcal {
        font-family: KaTeX_Caligraphic;
    }

    .katex .mathfrak {
        font-family: KaTeX_Fraktur;
    }

    .katex .mathtt {
        font-family: KaTeX_Typewriter;
    }

    .katex .mathscr {
        font-family: KaTeX_Script;
    }

    .katex .mathsf {
        font-family: KaTeX_SansSerif;
    }

    .katex .mainit {
        font-family: KaTeX_Main;
        font-style: italic;
    }

    .katex .mainrm {
        font-family: KaTeX_Main;
        font-style: normal;
    }

    .katex .vlist-t {
        display: inline-table;
        table-layout: fixed;
    }

    .katex .vlist-r {
        display: table-row;
    }

    .katex .vlist {
        display: table-cell;
        vertical-align: bottom;
        position: relative;
    }

    .katex .vlist > span {
        display: block;
        height: 0;
        position: relative;
    }

    .katex .vlist > span > span {
        display: inline-block;
    }

    .katex .vlist > span > .pstrut {
        overflow: hidden;
        width: 0;
    }

    .katex .vlist-t2 {
        margin-right: -2px;
    }

    .katex .vlist-s {
        display: table-cell;
        vertical-align: bottom;
        font-size: 1px;
        width: 2px;
    }

    .katex .msupsub {
        text-align: left;
    }

    .katex .mfrac > span > span {
        text-align: center;
    }

    .katex .mfrac .frac-line {
        display: inline-block;
        width: 100%;
    }

    .katex .mspace {
        display: inline-block;
    }

    .katex .mspace.negativethinspace {
        margin-left: -0.16667em;
    }

    .katex .mspace.muspace {
        width: 0.055556em;
    }

    .katex .mspace.thinspace {
        width: 0.16667em;
    }

    .katex .mspace.negativemediumspace {
        margin-left: -0.22222em;
    }

    .katex .mspace.mediumspace {
        width: 0.22222em;
    }

    .katex .mspace.thickspace {
        width: 0.27778em;
    }

    .katex .mspace.sixmuspace {
        width: 0.333333em;
    }

    .katex .mspace.eightmuspace {
        width: 0.444444em;
    }

    .katex .mspace.enspace {
        width: 0.5em;
    }

    .katex .mspace.twelvemuspace {
        width: 0.666667em;
    }

    .katex .mspace.quad {
        width: 1em;
    }

    .katex .mspace.qquad {
        width: 2em;
    }

    .katex .clap,
    .katex .llap,
    .katex .rlap {
        width: 0;
        position: relative;
    }

    .katex .clap > .inner,
    .katex .llap > .inner,
    .katex .rlap > .inner {
        position: absolute;
    }

    .katex .clap > .fix,
    .katex .llap > .fix,
    .katex .rlap > .fix {
        display: inline-block;
    }

    .katex .llap > .inner {
        right: 0;
    }

    .katex .clap > .inner,
    .katex .rlap > .inner {
        left: 0;
    }

    .katex .clap > .inner > span {
        margin-left: -50%;
        margin-right: 50%;
    }

    .katex .rule {
        display: inline-block;
        border: 0 solid;
        position: relative;
    }

    .katex .overline .overline-line,
    .katex .underline .underline-line {
        display: inline-block;
        width: 100%;
    }

    .katex .sqrt > .root {
        margin-left: 0.27777778em;
        margin-right: -0.55555556em;
    }

    .katex .fontsize-ensurer,
    .katex .sizing {
        display: inline-block;
    }

    .katex .fontsize-ensurer.reset-size1.size1,
    .katex .sizing.reset-size1.size1 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size1.size2,
    .katex .sizing.reset-size1.size2 {
        font-size: 1.2em;
    }

    .katex .fontsize-ensurer.reset-size1.size3,
    .katex .sizing.reset-size1.size3 {
        font-size: 1.4em;
    }

    .katex .fontsize-ensurer.reset-size1.size4,
    .katex .sizing.reset-size1.size4 {
        font-size: 1.6em;
    }

    .katex .fontsize-ensurer.reset-size1.size5,
    .katex .sizing.reset-size1.size5 {
        font-size: 1.8em;
    }

    .katex .fontsize-ensurer.reset-size1.size6,
    .katex .sizing.reset-size1.size6 {
        font-size: 2em;
    }

    .katex .fontsize-ensurer.reset-size1.size7,
    .katex .sizing.reset-size1.size7 {
        font-size: 2.4em;
    }

    .katex .fontsize-ensurer.reset-size1.size8,
    .katex .sizing.reset-size1.size8 {
        font-size: 2.88em;
    }

    .katex .fontsize-ensurer.reset-size1.size9,
    .katex .sizing.reset-size1.size9 {
        font-size: 3.456em;
    }

    .katex .fontsize-ensurer.reset-size1.size10,
    .katex .sizing.reset-size1.size10 {
        font-size: 4.148em;
    }

    .katex .fontsize-ensurer.reset-size1.size11,
    .katex .sizing.reset-size1.size11 {
        font-size: 4.976em;
    }

    .katex .fontsize-ensurer.reset-size2.size1,
    .katex .sizing.reset-size2.size1 {
        font-size: 0.83333333em;
    }

    .katex .fontsize-ensurer.reset-size2.size2,
    .katex .sizing.reset-size2.size2 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size2.size3,
    .katex .sizing.reset-size2.size3 {
        font-size: 1.16666667em;
    }

    .katex .fontsize-ensurer.reset-size2.size4,
    .katex .sizing.reset-size2.size4 {
        font-size: 1.33333333em;
    }

    .katex .fontsize-ensurer.reset-size2.size5,
    .katex .sizing.reset-size2.size5 {
        font-size: 1.5em;
    }

    .katex .fontsize-ensurer.reset-size2.size6,
    .katex .sizing.reset-size2.size6 {
        font-size: 1.66666667em;
    }

    .katex .fontsize-ensurer.reset-size2.size7,
    .katex .sizing.reset-size2.size7 {
        font-size: 2em;
    }

    .katex .fontsize-ensurer.reset-size2.size8,
    .katex .sizing.reset-size2.size8 {
        font-size: 2.4em;
    }

    .katex .fontsize-ensurer.reset-size2.size9,
    .katex .sizing.reset-size2.size9 {
        font-size: 2.88em;
    }

    .katex .fontsize-ensurer.reset-size2.size10,
    .katex .sizing.reset-size2.size10 {
        font-size: 3.45666667em;
    }

    .katex .fontsize-ensurer.reset-size2.size11,
    .katex .sizing.reset-size2.size11 {
        font-size: 4.14666667em;
    }

    .katex .fontsize-ensurer.reset-size3.size1,
    .katex .sizing.reset-size3.size1 {
        font-size: 0.71428571em;
    }

    .katex .fontsize-ensurer.reset-size3.size2,
    .katex .sizing.reset-size3.size2 {
        font-size: 0.85714286em;
    }

    .katex .fontsize-ensurer.reset-size3.size3,
    .katex .sizing.reset-size3.size3 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size3.size4,
    .katex .sizing.reset-size3.size4 {
        font-size: 1.14285714em;
    }

    .katex .fontsize-ensurer.reset-size3.size5,
    .katex .sizing.reset-size3.size5 {
        font-size: 1.28571429em;
    }

    .katex .fontsize-ensurer.reset-size3.size6,
    .katex .sizing.reset-size3.size6 {
        font-size: 1.42857143em;
    }

    .katex .fontsize-ensurer.reset-size3.size7,
    .katex .sizing.reset-size3.size7 {
        font-size: 1.71428571em;
    }

    .katex .fontsize-ensurer.reset-size3.size8,
    .katex .sizing.reset-size3.size8 {
        font-size: 2.05714286em;
    }

    .katex .fontsize-ensurer.reset-size3.size9,
    .katex .sizing.reset-size3.size9 {
        font-size: 2.46857143em;
    }

    .katex .fontsize-ensurer.reset-size3.size10,
    .katex .sizing.reset-size3.size10 {
        font-size: 2.96285714em;
    }

    .katex .fontsize-ensurer.reset-size3.size11,
    .katex .sizing.reset-size3.size11 {
        font-size: 3.55428571em;
    }

    .katex .fontsize-ensurer.reset-size4.size1,
    .katex .sizing.reset-size4.size1 {
        font-size: 0.625em;
    }

    .katex .fontsize-ensurer.reset-size4.size2,
    .katex .sizing.reset-size4.size2 {
        font-size: 0.75em;
    }

    .katex .fontsize-ensurer.reset-size4.size3,
    .katex .sizing.reset-size4.size3 {
        font-size: 0.875em;
    }

    .katex .fontsize-ensurer.reset-size4.size4,
    .katex .sizing.reset-size4.size4 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size4.size5,
    .katex .sizing.reset-size4.size5 {
        font-size: 1.125em;
    }

    .katex .fontsize-ensurer.reset-size4.size6,
    .katex .sizing.reset-size4.size6 {
        font-size: 1.25em;
    }

    .katex .fontsize-ensurer.reset-size4.size7,
    .katex .sizing.reset-size4.size7 {
        font-size: 1.5em;
    }

    .katex .fontsize-ensurer.reset-size4.size8,
    .katex .sizing.reset-size4.size8 {
        font-size: 1.8em;
    }

    .katex .fontsize-ensurer.reset-size4.size9,
    .katex .sizing.reset-size4.size9 {
        font-size: 2.16em;
    }

    .katex .fontsize-ensurer.reset-size4.size10,
    .katex .sizing.reset-size4.size10 {
        font-size: 2.5925em;
    }

    .katex .fontsize-ensurer.reset-size4.size11,
    .katex .sizing.reset-size4.size11 {
        font-size: 3.11em;
    }

    .katex .fontsize-ensurer.reset-size5.size1,
    .katex .sizing.reset-size5.size1 {
        font-size: 0.55555556em;
    }

    .katex .fontsize-ensurer.reset-size5.size2,
    .katex .sizing.reset-size5.size2 {
        font-size: 0.66666667em;
    }

    .katex .fontsize-ensurer.reset-size5.size3,
    .katex .sizing.reset-size5.size3 {
        font-size: 0.77777778em;
    }

    .katex .fontsize-ensurer.reset-size5.size4,
    .katex .sizing.reset-size5.size4 {
        font-size: 0.88888889em;
    }

    .katex .fontsize-ensurer.reset-size5.size5,
    .katex .sizing.reset-size5.size5 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size5.size6,
    .katex .sizing.reset-size5.size6 {
        font-size: 1.11111111em;
    }

    .katex .fontsize-ensurer.reset-size5.size7,
    .katex .sizing.reset-size5.size7 {
        font-size: 1.33333333em;
    }

    .katex .fontsize-ensurer.reset-size5.size8,
    .katex .sizing.reset-size5.size8 {
        font-size: 1.6em;
    }

    .katex .fontsize-ensurer.reset-size5.size9,
    .katex .sizing.reset-size5.size9 {
        font-size: 1.92em;
    }

    .katex .fontsize-ensurer.reset-size5.size10,
    .katex .sizing.reset-size5.size10 {
        font-size: 2.30444444em;
    }

    .katex .fontsize-ensurer.reset-size5.size11,
    .katex .sizing.reset-size5.size11 {
        font-size: 2.76444444em;
    }

    .katex .fontsize-ensurer.reset-size6.size1,
    .katex .sizing.reset-size6.size1 {
        font-size: 0.5em;
    }

    .katex .fontsize-ensurer.reset-size6.size2,
    .katex .sizing.reset-size6.size2 {
        font-size: 0.6em;
    }

    .katex .fontsize-ensurer.reset-size6.size3,
    .katex .sizing.reset-size6.size3 {
        font-size: 0.7em;
    }

    .katex .fontsize-ensurer.reset-size6.size4,
    .katex .sizing.reset-size6.size4 {
        font-size: 0.8em;
    }

    .katex .fontsize-ensurer.reset-size6.size5,
    .katex .sizing.reset-size6.size5 {
        font-size: 0.9em;
    }

    .katex .fontsize-ensurer.reset-size6.size6,
    .katex .sizing.reset-size6.size6 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size6.size7,
    .katex .sizing.reset-size6.size7 {
        font-size: 1.2em;
    }

    .katex .fontsize-ensurer.reset-size6.size8,
    .katex .sizing.reset-size6.size8 {
        font-size: 1.44em;
    }

    .katex .fontsize-ensurer.reset-size6.size9,
    .katex .sizing.reset-size6.size9 {
        font-size: 1.728em;
    }

    .katex .fontsize-ensurer.reset-size6.size10,
    .katex .sizing.reset-size6.size10 {
        font-size: 2.074em;
    }

    .katex .fontsize-ensurer.reset-size6.size11,
    .katex .sizing.reset-size6.size11 {
        font-size: 2.488em;
    }

    .katex .fontsize-ensurer.reset-size7.size1,
    .katex .sizing.reset-size7.size1 {
        font-size: 0.41666667em;
    }

    .katex .fontsize-ensurer.reset-size7.size2,
    .katex .sizing.reset-size7.size2 {
        font-size: 0.5em;
    }

    .katex .fontsize-ensurer.reset-size7.size3,
    .katex .sizing.reset-size7.size3 {
        font-size: 0.58333333em;
    }

    .katex .fontsize-ensurer.reset-size7.size4,
    .katex .sizing.reset-size7.size4 {
        font-size: 0.66666667em;
    }

    .katex .fontsize-ensurer.reset-size7.size5,
    .katex .sizing.reset-size7.size5 {
        font-size: 0.75em;
    }

    .katex .fontsize-ensurer.reset-size7.size6,
    .katex .sizing.reset-size7.size6 {
        font-size: 0.83333333em;
    }

    .katex .fontsize-ensurer.reset-size7.size7,
    .katex .sizing.reset-size7.size7 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size7.size8,
    .katex .sizing.reset-size7.size8 {
        font-size: 1.2em;
    }

    .katex .fontsize-ensurer.reset-size7.size9,
    .katex .sizing.reset-size7.size9 {
        font-size: 1.44em;
    }

    .katex .fontsize-ensurer.reset-size7.size10,
    .katex .sizing.reset-size7.size10 {
        font-size: 1.72833333em;
    }

    .katex .fontsize-ensurer.reset-size7.size11,
    .katex .sizing.reset-size7.size11 {
        font-size: 2.07333333em;
    }

    .katex .fontsize-ensurer.reset-size8.size1,
    .katex .sizing.reset-size8.size1 {
        font-size: 0.34722222em;
    }

    .katex .fontsize-ensurer.reset-size8.size2,
    .katex .sizing.reset-size8.size2 {
        font-size: 0.41666667em;
    }

    .katex .fontsize-ensurer.reset-size8.size3,
    .katex .sizing.reset-size8.size3 {
        font-size: 0.48611111em;
    }

    .katex .fontsize-ensurer.reset-size8.size4,
    .katex .sizing.reset-size8.size4 {
        font-size: 0.55555556em;
    }

    .katex .fontsize-ensurer.reset-size8.size5,
    .katex .sizing.reset-size8.size5 {
        font-size: 0.625em;
    }

    .katex .fontsize-ensurer.reset-size8.size6,
    .katex .sizing.reset-size8.size6 {
        font-size: 0.69444444em;
    }

    .katex .fontsize-ensurer.reset-size8.size7,
    .katex .sizing.reset-size8.size7 {
        font-size: 0.83333333em;
    }

    .katex .fontsize-ensurer.reset-size8.size8,
    .katex .sizing.reset-size8.size8 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size8.size9,
    .katex .sizing.reset-size8.size9 {
        font-size: 1.2em;
    }

    .katex .fontsize-ensurer.reset-size8.size10,
    .katex .sizing.reset-size8.size10 {
        font-size: 1.44027778em;
    }

    .katex .fontsize-ensurer.reset-size8.size11,
    .katex .sizing.reset-size8.size11 {
        font-size: 1.72777778em;
    }

    .katex .fontsize-ensurer.reset-size9.size1,
    .katex .sizing.reset-size9.size1 {
        font-size: 0.28935185em;
    }

    .katex .fontsize-ensurer.reset-size9.size2,
    .katex .sizing.reset-size9.size2 {
        font-size: 0.34722222em;
    }

    .katex .fontsize-ensurer.reset-size9.size3,
    .katex .sizing.reset-size9.size3 {
        font-size: 0.40509259em;
    }

    .katex .fontsize-ensurer.reset-size9.size4,
    .katex .sizing.reset-size9.size4 {
        font-size: 0.46296296em;
    }

    .katex .fontsize-ensurer.reset-size9.size5,
    .katex .sizing.reset-size9.size5 {
        font-size: 0.52083333em;
    }

    .katex .fontsize-ensurer.reset-size9.size6,
    .katex .sizing.reset-size9.size6 {
        font-size: 0.5787037em;
    }

    .katex .fontsize-ensurer.reset-size9.size7,
    .katex .sizing.reset-size9.size7 {
        font-size: 0.69444444em;
    }

    .katex .fontsize-ensurer.reset-size9.size8,
    .katex .sizing.reset-size9.size8 {
        font-size: 0.83333333em;
    }

    .katex .fontsize-ensurer.reset-size9.size9,
    .katex .sizing.reset-size9.size9 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size9.size10,
    .katex .sizing.reset-size9.size10 {
        font-size: 1.20023148em;
    }

    .katex .fontsize-ensurer.reset-size9.size11,
    .katex .sizing.reset-size9.size11 {
        font-size: 1.43981481em;
    }

    .katex .fontsize-ensurer.reset-size10.size1,
    .katex .sizing.reset-size10.size1 {
        font-size: 0.24108004em;
    }

    .katex .fontsize-ensurer.reset-size10.size2,
    .katex .sizing.reset-size10.size2 {
        font-size: 0.28929605em;
    }

    .katex .fontsize-ensurer.reset-size10.size3,
    .katex .sizing.reset-size10.size3 {
        font-size: 0.33751205em;
    }

    .katex .fontsize-ensurer.reset-size10.size4,
    .katex .sizing.reset-size10.size4 {
        font-size: 0.38572806em;
    }

    .katex .fontsize-ensurer.reset-size10.size5,
    .katex .sizing.reset-size10.size5 {
        font-size: 0.43394407em;
    }

    .katex .fontsize-ensurer.reset-size10.size6,
    .katex .sizing.reset-size10.size6 {
        font-size: 0.48216008em;
    }

    .katex .fontsize-ensurer.reset-size10.size7,
    .katex .sizing.reset-size10.size7 {
        font-size: 0.57859209em;
    }

    .katex .fontsize-ensurer.reset-size10.size8,
    .katex .sizing.reset-size10.size8 {
        font-size: 0.69431051em;
    }

    .katex .fontsize-ensurer.reset-size10.size9,
    .katex .sizing.reset-size10.size9 {
        font-size: 0.83317261em;
    }

    .katex .fontsize-ensurer.reset-size10.size10,
    .katex .sizing.reset-size10.size10 {
        font-size: 1em;
    }

    .katex .fontsize-ensurer.reset-size10.size11,
    .katex .sizing.reset-size10.size11 {
        font-size: 1.19961427em;
    }

    .katex .fontsize-ensurer.reset-size11.size1,
    .katex .sizing.reset-size11.size1 {
        font-size: 0.20096463em;
    }

    .katex .fontsize-ensurer.reset-size11.size2,
    .katex .sizing.reset-size11.size2 {
        font-size: 0.24115756em;
    }

    .katex .fontsize-ensurer.reset-size11.size3,
    .katex .sizing.reset-size11.size3 {
        font-size: 0.28135048em;
    }

    .katex .fontsize-ensurer.reset-size11.size4,
    .katex .sizing.reset-size11.size4 {
        font-size: 0.32154341em;
    }

    .katex .fontsize-ensurer.reset-size11.size5,
    .katex .sizing.reset-size11.size5 {
        font-size: 0.36173633em;
    }

    .katex .fontsize-ensurer.reset-size11.size6,
    .katex .sizing.reset-size11.size6 {
        font-size: 0.40192926em;
    }

    .katex .fontsize-ensurer.reset-size11.size7,
    .katex .sizing.reset-size11.size7 {
        font-size: 0.48231511em;
    }

    .katex .fontsize-ensurer.reset-size11.size8,
    .katex .sizing.reset-size11.size8 {
        font-size: 0.57877814em;
    }

    .katex .fontsize-ensurer.reset-size11.size9,
    .katex .sizing.reset-size11.size9 {
        font-size: 0.69453376em;
    }

    .katex .fontsize-ensurer.reset-size11.size10,
    .katex .sizing.reset-size11.size10 {
        font-size: 0.83360129em;
    }

    .katex .fontsize-ensurer.reset-size11.size11,
    .katex .sizing.reset-size11.size11 {
        font-size: 1em;
    }

    .katex .delimsizing.size1 {
        font-family: KaTeX_Size1;
    }

    .katex .delimsizing.size2 {
        font-family: KaTeX_Size2;
    }

    .katex .delimsizing.size3 {
        font-family: KaTeX_Size3;
    }

    .katex .delimsizing.size4 {
        font-family: KaTeX_Size4;
    }

    .katex .delimsizing.mult .delim-size1 > span {
        font-family: KaTeX_Size1;
    }

    .katex .delimsizing.mult .delim-size4 > span {
        font-family: KaTeX_Size4;
    }

    .katex .nulldelimiter {
        display: inline-block;
        width: 0.12em;
    }

    .katex .delimcenter,
    .katex .op-symbol {
        position: relative;
    }

    .katex .op-symbol.small-op {
        font-family: KaTeX_Size1;
    }

    .katex .op-symbol.large-op {
        font-family: KaTeX_Size2;
    }

    .katex .accent > .vlist-t,
    .katex .op-limits > .vlist-t {
        text-align: center;
    }

    .katex .accent .accent-body {
        width: 0;
        position: relative;
    }

    .katex .overlay {
        display: block;
    }

    .katex .mtable .vertical-separator {
        display: inline-block;
        margin: 0 -0.125em;
        width: 0.25em;
        overflow: hidden;
        position: relative;
    }

    .katex .mtable .arraycolsep {
        display: inline-block;
    }

    .katex .mtable .col-align-c > .vlist-t {
        text-align: center;
    }

    .katex .mtable .col-align-l > .vlist-t {
        text-align: left;
    }

    .katex .mtable .col-align-r > .vlist-t {
        text-align: right;
    }

    .katex .svg-align {
        text-align: left;
    }

    .katex svg {
        display: block;
        position: absolute;
        width: 100%;
        fill: currentColor;
        stroke: currentColor;
        fill-rule: nonzero;
        fill-opacity: 1;
        stroke-width: 1;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-dashoffset: 0;
        stroke-opacity: 1;
    }

    .katex svg path {
        stroke: none;
    }

    .katex .vertical-separator svg {
        width: 0.25em;
    }

    .katex .stretchy {
        width: 100%;
        display: block;
        position: relative;
        overflow: hidden;
    }

    .katex .stretchy:after,
    .katex .stretchy:before {
        content: "";
    }

    .katex .hide-tail {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .katex .halfarrow-left {
        position: absolute;
        left: 0;
        width: 50.2%;
        overflow: hidden;
    }

    .katex .halfarrow-right {
        position: absolute;
        right: 0;
        width: 50.2%;
        overflow: hidden;
    }

    .katex .brace-left {
        position: absolute;
        left: 0;
        width: 25.1%;
        overflow: hidden;
    }

    .katex .brace-center {
        position: absolute;
        left: 25%;
        width: 50%;
        overflow: hidden;
    }

    .katex .brace-right {
        position: absolute;
        right: 0;
        width: 25.1%;
        overflow: hidden;
    }

    .katex .x-arrow-pad {
        padding: 0 0.5em;
    }

    .katex .mover,
    .katex .munder,
    .katex .x-arrow {
        text-align: center;
    }

    .katex .boxpad {
        padding: 0 0.3em;
    }

    .katex .fbox {
        border: 0.04em solid #000;
    }

    .katex .fbox,
    .katex .fcolorbox {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .katex .fcolorbox {
        border: 0.04em solid;
    }

    .katex .cancel-pad {
        padding: 0 0.2em;
    }

    .katex .cancel-lap + .mbin,
    .katex .cancel-lap + .mord,
    .katex .cancel-lap + .msupsub,
    .katex .mbin + .cancel-lap,
    .katex .mord + .cancel-lap {
        margin-left: -0.2em;
    }

    .katex .sout {
        border-bottom-style: solid;
        border-bottom-width: 0.08em;
    }
}
